.container {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
}

.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.imageUploadBtn {
    width: 173px;
    height: 127.24px;
    background: #d1d1d1;
    border: 1px solid #bababa;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.audioUploadBtn {
    width: 138px;
    height: 33px;
    background: #d1d1d1;
    border: 1px solid #bababa;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.imageUploadBtnLabel {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: var(--black-three);
    cursor: pointer;
}

.audioUploadBtnLabel {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: var(--black-three);
    cursor: pointer;
    margin-left: 8px;
}

.imageRemoveBtnLabel {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: var(--black-three);
    cursor: pointer;
    margin-top: 5px;
    text-align: center;
}

.imgPreview {
    width: 173px;
    height: 127.24px;
    object-fit: cover;
}

.titleField {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: var(--black-one);
    margin-bottom: 8px;
}

.paddingSecondColumn {
    padding-left: 32px;
    padding-right: 24px;
}

.paddingLastColumn {
    padding-top: 28px;
}

.marginBottom8 {
    margin-bottom: 8px;
}

.alignEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 24px;
    width: 95%;
}

.cancelBtn {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: var(--red);
    margin-right: 32px;
    cursor: pointer;
}

.saveBtn {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: var(--green-two);
    cursor: pointer;
}

.disabledCancelBtn {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: var(#d1d1d1);
    margin-right: 32px;
    cursor: pointer;
}

.disabledSaveBtn {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: var(#d1d1d1);
    cursor: pointer;
}
