body {
    height: 100vh;
    width: 100vw;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', 'Nunito', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    margin: 0 !important;
    padding: 0 !important;

    background-color: var(--background-white);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

:root {
    --background-white: ##e5e5e5;
    --background-white-two: #f2f2f2;
    --background-blue: #4e77c1;
    --white: #ffffff;
    --white-two: #fbfbfb;
    --white-tree: #e4e1e1;
    --white-four: #fdfafa;
    --purple: #b772b9;
    --red: #ea4335;
    --blue: #4e77c1;
    --blue-two: #7e9cd1;
    --blue-dark: #40629e;
    --black: #353535;
    --black-one: #5e5e64;
    --black-two: #515151;
    --black-three: #787878;
    --green: #009d70;
    --green-two: #36b37e;
    --grey-light: #d1d1d1;
}

#root,
#root > * {
    width: 100%;
}

@media (min-height: 688px) {
    #root > * {
        height: 100%;
    }
}

@media screen and (max-width: 1366px) {
    html {
        font-size: 14px;
    }
}

@media screen and (max-width: 1600px) {
    html {
        font-size: 16px;
    }
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.margin-bottom-24 {
    margin-bottom: 24px;
}

.margin-right-16 {
    margin-right: 16px;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.centering {
    display: flex;
    justify-content: center;
    align-items: center;
}

.padding-top-8 {
    padding-top: 8px;
}
